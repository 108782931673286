<template>
  <section id="gallery">
    <div id="inside">
      <h1>PEŁEN PROFESJONALIZM W KAŻDYM ASPEKCIE</h1>
      <h2>Zobacz galerie wykonanych przez nas zleceń</h2>
      <section id="imageList">
        <div
          class="image"
          v-for="n in galleryItems"
          :key="n"
          v-bind:style="{
            backgroundImage:
              'url(' + require('@/assets/images/gallery/' + n + '.jpg') + ')',
          }"
          v-on:click="openGallery(n)"
        >
          <div class="localization">
            <i class="icon icon-location" v-on:click="nextImage()">
              &#xe805;
            </i>
            {{ imagesLocalized[n - 1] }}
          </div>
        </div>
      </section>
    </div>
    <section id="fullScreenImage">
      <i class="icon icon-left-open-mini" v-on:click="prevImage()">
        &#xe804;
      </i>
      <img
        :src="require('@/assets/images/gallery/' + this.selectedImage + '.jpg')"
      />
      <i class="icon icon-right-open-mini" v-on:click="nextImage()">
        &#xe803;
      </i>
    </section>
  </section>
</template>

<script>
export default {
  created() {
    document.addEventListener("click", (event) => {
      if (event.target == document.getElementById("fullScreenImage"))
        this.closeGallery();
    });

    document.addEventListener("keydown", (event) => {
      let keys = { left: 37, right: 39, esc: 27 };

      switch (event.keyCode) {
        case keys.left:
          this.prevImage();
          break;
        case keys.right:
          this.nextImage();
          break;
        case keys.esc:
          this.closeGallery();
          break;
      }
    });
  },
  data() {
    return {
      galleryItems: 30,
      selectedImage: 1,
      imagesLocalized: [
        "Niepołomice",
        "Niepołomice",
        "Niepołomice",
        "Niepołomice",
        "Niepołomice",
        "Niepołomice",
        "Niepołomice",
        "Wawrzeńczyce",
        "Wawrzeńczyce",
        "Wawrzeńczyce",
        "Wawrzeńczyce",
        "Wawrzeńczyce",
        "Stronie",
        "Stronie",
        "Stronie",
        "Stronie",
        "Stronie",
        "Stronie",
        "Stronie",
        "Stronie",
        "Stronie",
        "Stronie",
        "Stronie",
        "Stronie",
        "Katowice",
        "Katowice",
        "Katowice",
        "Katowice",
        "Katowice",
        "Katowice",
      ],
    };
  },
  methods: {
    openGallery: function (imgIndex) {
      this.selectedImage = imgIndex;
      document.getElementById("fullScreenImage").style.display = "flex";
    },
    closeGallery: function () {
      document.getElementById("fullScreenImage").style.display = "none";
    },
    nextImage: function () {
      if (this.selectedImage < this.galleryItems) this.selectedImage++;
      else this.selectedImage = 1;
    },
    prevImage: function () {
      if (this.selectedImage > 1) this.selectedImage--;
      else this.selectedImage = this.galleryItems;
    },
  },
};
</script>

<style scoped>
section#gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
}

section#gallery div#inside {
  width: 60%;
  margin: 120px 0 0 0;
}

section#gallery div#inside h1 {
  font-size: 1.4rem;
  color: #6ba421;
  margin: 60px 0 30px 0;
  font-weight: 600;
}

section#gallery div#inside h2 {
  font-size: 2rem;
  line-height: 2.4rem;
  margin: 0 0 30px 0;
  font-weight: 600;
  width: 70%;
}

section#gallery div#inside h3 {
  font-size: 1rem;
  margin: 0 0 30px 0;
  width: 70%;
  line-height: 1.2rem;
}

section#gallery div#inside h3 b {
  font-weight: 600;
}

section#gallery div#inside section#imageList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 0 60px 0;
}

section#gallery div#inside section#imageList div.image {
  width: 30%;
  height: 300px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  clip-path: polygon(0 10%, 100% 0, 100% 90%, 0% 100%);
  cursor: pointer;
  transition: 0.3s ease;
}

section#gallery div#inside section#imageList div.image div.localization {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 1.4rem;
  align-items: center;
  color: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0);
  transition: 0.3s ease;
}

section#gallery div#inside section#imageList div.image div.localization:hover {
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
}

section#gallery section#fullScreenImage {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10000;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

section#gallery section#fullScreenImage i.icon-left-open-mini {
  color: #fff;
  font-size: 50px;
  cursor: pointer;
  transition: 0.3s ease;
}

section#gallery section#fullScreenImage i.icon-left-open-mini:hover {
  color: #aaa;
}

section#gallery section#fullScreenImage i.icon-right-open-mini {
  color: #fff;
  font-size: 50px;
  cursor: pointer;
  transition: 0.3s ease;
}

section#gallery section#fullScreenImage i.icon-right-open-mini:hover {
  color: #aaa;
}

section#gallery section#fullScreenImage img {
  max-width: 80%;
  max-height: 80%;
}

@media only screen and (max-width: 1280px) {
  section#gallery div#inside {
    width: 80%;
    margin: 60px 0 0 0;
  }

  section#gallery div#inside h2 {
    font-size: 1.6rem;
    line-height: 2rem;
    width: 100%;
  }

  section#gallery div#inside section#imageList div.image {
    width: 100%;
  }
}
</style>
